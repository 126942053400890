import { reactive, computed, onMounted, watch } from "vue";

import { useApp } from '@/composables/app';
import { useStore } from 'vuex';

export function useAddPage({props, formData, v$, onFormSubmited, beforeSubmit}) {
	const app = useApp();
	const store = useStore();
	const state = reactive({
		id: null,
		pageReady: false,
		submitted: false,
		saving: false,
		errorMsg: ''
	});

	const apiUrl = computed(() => {
		return props.apiPath
	});

	function validateForm(){
		state.submitted = true;
		v$.value.$validate();
		if(v$.value.$invalid){
			return false;
		}
		return true;
	}

	async function submitForm(){
		if(beforeSubmit !== undefined){
			if(!beforeSubmit()){ return; }
		}

		if(!validateForm()){
			app.flashMsg("Form is invalid", "Please complete the form", "error");
			return;
		}

		state.saving = true;
		let url = apiUrl.value;
		let data = { url, payload: formData  }
		try{
			let response = await store.dispatch(`${props.pageName}/saveRecord`, data);
			state.saving = false;
			state.submitted = false;
			app.closeDialogs();// close page dialog that if opened
			onFormSubmited(response);
		}
		catch(e) {
			state.saving = false;
			app.showPageRequestError(e);
		}
	}

	function resetForm(){
		if(Array.isArray(formData)){
			formData = [{...props.formInputs}];  //reset form data
		}
		else{
			Object.assign(formData, props.formInputs); //reset form data
		}
	}


	function getErrorClass(field, index){
		let isInvalid = false;
		if(index === undefined){
			isInvalid = v$.value[field].$invalid && state.submitted;
		}
		//for multi form validation
		else if(v$.value.$each.$response.$errors[index][field].length && state.submitted){
			isInvalid = true;
		}
		return {"p-invalid": isInvalid };
	}

	function isFieldValid(field, index){
		if(index===undefined){
			return v$.value[field].$invalid && state.submitted;	
		}
		else if(v$.value.$each.$response.$errors[index][field].length && state.submitted){
			return true;
		}
		return false;
	}
	

	function getFieldError(field, index){
		let fieldErrors = null;
		if(index===undefined){
			fieldErrors = v$.value[field].$silentErrors;
			if(fieldErrors.length){
				return fieldErrors[0].$message; //return the first error
			}
		}
		else{
			fieldErrors = v$.value.$each.$response.$errors[index][field];
			if(fieldErrors.length){
				return fieldErrors[0].$message; //return the first error
			}
		}
		return null
	}
	
	
	watch(() => props.pageData, (current) => {
		Object.assign(formData, current);
	  },
	  { deep: true, immediate: true }
  	);

	onMounted(()=>{ 
		state.pageReady = true;
	});

	const computedProps = {
		apiUrl,
	}

	const methods = {
		submitForm,
		getErrorClass,
		getFieldError,
		isFieldValid
	}
	
	return {
		validateForm,
		resetForm,
		formData,
		state,
		computedProps,
		methods
	}

}